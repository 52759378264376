import { motion } from "framer-motion";

export default function App() {
  const titleText = "Coming Soon";
  const subtitleText =
    "We’re working hard to bring you something incredibly musical!";
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-primary to-primary-foreground text-primary-foreground p-4">
      <main className="text-center flex flex-col items-center justify-center">
        <img src={"./logo_with_bg.png"} width={100} alt="Tune Club" />
        <h1 className="text-xl md:text-2xl font-bold mb-4">TuneClub</h1>
        <h2 className="text-4xl md:text-6xl font-bold mb-4">
          {titleText.split("").map((char, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.5,
                delay: index * 0.1,
                ease: [0.6, -0.05, 0.01, 0.99],
              }}
              className="inline-block"
            >
              {char}
            </motion.span>
          ))}
        </h2>
        <motion.p
          className="text-xl md:text-2xl mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: titleText.length * 0.1 }}
        >
          {subtitleText}
        </motion.p>
      </main>
    </div>
  );
}
